<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <vs-progress
              v-if="validating"
              indeterminate
              color="primary"
            ></vs-progress>
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                height="200px"
                src="@/assets/images/pages/license.png"
                alt="Licence Verification"
                class="mx-auto"
              />
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">License Required</h4>
                  <p>
                    A valid licence from our organization is required to open
                    this system.
                    <span v-if="activation != {}"
                      ><br />Your license that was issued on
                      <strong>{{ parseDate(activation.start) }}</strong> expired
                      on
                      <strong>{{ parseDate(activation.expiry) }}</strong></span
                    >
                  </p>
                </div>

                <p>Your installation ID is listed below.</p>
                <br />
                <vs-textarea label="Installation ID" v-model="machineID" />
                <p>
                  Please contact our support team <b>+254 710 710 037</b> with
                  the above <b>ID</b> to aquire a new license key then copy and
                  paste below.
                </p>

                <vs-textarea
                  v-validate="'required|min:162'"
                  name="license"
                  data-vv-name="license"
                  class="w-full mt-8"
                  label="Enter License Key"
                  v-model="license"
                />
                <span class="text-danger text-sm">{{
                  errors.first('license')
                }}</span>

                <div class="flex flex-wrap justify-between mt-6">
                  <vs-button
                    class="flex flex-wrap"
                    :disabled="!validateForm"
                    @click="validate"
                    >Validate</vs-button
                  >
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import idb from '@/db/idb';
import crypto from 'crypto';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {},
  data() {
    return {
      license: '',
      machineID: 'Getting installation ID ...',
      validating: false,
      publicKey: '',
      activation: {},
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.license && !this.validating;
    },
  },
  pouch: {
    activationData() {
      return {
        database: 'express',
        selector: { _id: this.machineID },
        first: true,
      };
    },
    businessData() {
      return {
        database: 'express',
        selector: { _id: 'business' },
        first: true,
      };
    },
  },
  methods: {
    parseDate(d) {
      return this.$moment(d).format('dddd, MMMM Do YYYY');
    },
    async getMachineID() {
      var k = await idb.getRecord('installationID');
      if (k) {
        this.machineID = k.data;
      } else {
        var uuid = uuidv4();
        idb.saveRecord({
          type: 'installationID',
          data: uuid,
        });
        this.machineID = uuid;
      }
    },
    decrypt(a) {
      try {
        let key = Buffer.from(a[0], 'hex');
        let iv = Buffer.from(a[1], 'hex');
        let en = Buffer.from(a[2], 'hex');
        let decipher = crypto.createDecipheriv(
          'aes-256-cbc',
          Buffer.from(key),
          iv,
        );
        let de = decipher.update(en);
        de = Buffer.concat([de, decipher.final()]);
        return JSON.parse(atob(de.toString()));
      } catch (err) {
        return false;
      }
    },
    verify(d) {
      try {
        const verify = crypto.createVerify('RSA-SHA256');
        verify.update(new Buffer.from(JSON.stringify(d.data)));
        return verify.verify(this.publicKey, d.signature, 'hex');
      } catch (e) {
        return false;
      }
    },
    showError(message) {
      this.$vs.notify({
        title: 'Error',
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
        time: 13000,
      });
    },
    async validate() {
      if (this.validateForm) {
        var a = this.license.split(':');
        if (a.length != 3) {
          this.loading = false;
          this.showError(
            'Invalid License Key Provided. License keys should match our signature. Please contact our Support Team.',
          );
        } else {
          var c = false;
          var d = this.decrypt(a);
          if (d != false) {
            var chs = this.verify(d);
            if (chs) {
              var data = d.data;
              if (data.machineID === this.machineID) {
                var today = new Date();
                var date = new Date(data.expiry);
                console.log(`Today ${today.getTime()} v exp ${date.getTime()}`);
                if (date.getTime() < today.getTime()) {
                  this.showError(
                    'The license you entered expired. Please request for another.',
                  );
                } else {
                  await idb.saveRecord({
                    type: 'license',
                    installationID: data.machineID,
                    license: this.license,
                    expiry: data.expiry,
                    start: data.start,
                    date: Date.now(),
                  });
                  await idb.saveRecord({
                    type: 'activation',
                    active: true,
                    license: this.license,
                    start: new Date(data.start).setHours(0, 0, 0, 0),
                    expiry: data.expiry,
                    date: new Date(Date.now()),
                  });

                  await this.$pouch.put(
                    {
                      _id: data.machineID,
                      _rev: this.activationData._rev || undefined,
                      type: 'activation',
                      active: true,
                      machineID: data.machineID,
                      license: this.license,
                      start: new Date(data.start).setHours(0, 0, 0, 0),
                      expiry: data.expiry,
                      date: new Date(Date.now()),
                      date_updated: Date.now(),
                    },
                    {},
                    'express',
                  );

                  this.$vs.notify({
                    title: 'Cheers',
                    text: `Thank you for choosing Express POS. Your license key has been successfully verified 
                          and will expire on ${this.$moment(data.expiry).format(
                            'dddd, MMMM Do YYYY',
                          )}`,
                    iconPack: 'feather',
                    icon: 'icon-check-circle',
                    color: 'success',
                    time: 13000,
                  });
                  setTimeout(() => {
                    if (this.$route.name !== 'login') {
                      // this.$router.push({ name: 'login' });
                      window.location = '/login';
                    }
                  }, 2000);
                }
              } else {
                this.showError(
                  'This license belongs to another PC. Request one for your PC.',
                );
              }
            } else {
              this.showError('License contains an invalid signature');
            }
          } else {
            this.showError('Invalid License Provided');
          }
        }
      }
    },
  },
  async created() {
    this.activation = (await idb.getRecord('activation')) || null;
    if (this.activation) {
      this.$vs.notify({
        title: 'License Expired',
        text: 'Your license expired, please contact our Support Team for a renewal',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
        time: 15000,
      });
    } else {
      this.$vs.notify({
        title: "You're not licensed",
        text: 'You are not using any license, contact our Support Team for a license',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
        time: 15000,
      });
    }
    this.publicKey =
      '-----BEGIN PUBLIC KEY-----\n' +
      'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgGC7ihHUgBC5NdYRF/xxGKGdhn2W\n' +
      '2IXRhaKYZEJvr1s6t+tQMN9yYvhr4j3eZk1zJlMgXOXFr0mOncA3UnLVNQPwsitK\n' +
      'bjh2yWA99lNiW3fAWhlanX2RJPbVP/wuueI5URZXXaxkpH/b/RSswXvjpof+kjED\n' +
      'YRf6+GxCrzJyof/1AgMBAAE=\n' +
      '-----END PUBLIC KEY-----';
    this.getMachineID();
  },
};
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
